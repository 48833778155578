import { graphql } from "gatsby";
import BlogComponent from "../components/blog";

export default BlogComponent;

export const query = graphql`
  query($formatString: String!, $skip: Int!, $limit: Int!) {
    allPost(sort: { order: DESC, fields: date }, limit: $limit, skip: $skip) {
      nodes {
        slug
        title
        date(formatString: $formatString)
        timeToRead
        description
        tags {
          name
          slug
        }
        banner {
          childImageSharp {
            fluid(
              maxWidth: 500
              maxHeight: 200
              quality: 90
              srcSetBreakpoints: [200, 500]
            ) {
              src
              srcSet
              aspectRatio
              sizes
              base64
            }
          }
        }
      }
    }
  }
`;
// export const query = graphql`
//   query($formatString: String!, $skip: Int!, $limit: Int!) {
//     allPost(
//       sort: { order: DESC, fields: date }
//       filter: {
//         banner: { childImageSharp: { fluid: { src: { regex: "/jpg|png/" } } } }
//       }
//       limit: $limit
//       skip: $skip
//     ) {
//       nodes {
//         slug
//         title
//         date(formatString: $formatString)
//         timeToRead
//         description
//         tags {
//           name
//           slug
//         }
//         banner {
//           childImageSharp {
//             fluid(
//               maxWidth: 500
//               maxHeight: 200
//               quality: 90
//               srcSetBreakpoints: [200, 500]
//             ) {
//               src
//               srcSet
//               aspectRatio
//               sizes
//               base64
//             }
//           }
//         }
//       }
//     }
//   }
// `;
