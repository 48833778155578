/** @jsx jsx */
import React from "react"
import { jsx, Styled } from "theme-ui"
import { Grid, Box } from "@theme-ui/components"
import { Link } from "gatsby"
import ItemTags from "./item-tags"
import Img from "gatsby-image"

type FooterLatestPostsItemProps = {
  post: {
    slug: string
    title: string
    date: string
    excerpt: string
    description: string
    timeToRead?: number
    tags?: {
      name: string
      slug: string
    }[]
    banner?: {
      childImageSharp: {
        fluid
      }
    }
  }
  showTags?: boolean
}

const FooterLatestPostsItem = (
    { post, showTags = true }: FooterLatestPostsItemProps
  ) => {

  return (
    <Grid
      gap={2}
      columns={[1]}
    
    >
      <Box mb={1}>
        <Styled.a as={Link} to={post.slug} >
          <section>
            <section
              sx={{
                mb: 1,
                pt: 2,
                img: {
                  borderRadius: '5px',
                  objectPosition: '50% 30%',
                },
                ":hover": {
                  transition: 'all 0.4s ease',
                  transform: 'translate3D(0, -1px, 0) scale(1.02)'
                }
              }}
            >
              {post.banner &&
                <Img
                  imgStyle={{
                    img: {
                      borderRadius: '5px',
                    },
                  }}
                  fluid={post.banner.childImageSharp.fluid}
                />
              }
            </section>
          </section>
        </Styled.a>
      </Box>


      <Box mb={1}>
        <Styled.a as={Link} to={post.slug} >
          <section>
            <section
              sx={{
                fontSize: [1, 1, 1],
              }}
            >
              {post.title}
            </section>
          </section>
        </Styled.a>
      </Box>
    </Grid>
  )
}

export default FooterLatestPostsItem
