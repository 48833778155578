/** @jsx jsx */
import { jsx, Styled } from "theme-ui";
import { Link } from "gatsby";
import { Flex } from "@theme-ui/components";
import Layout from "./layout";
import Listing from "./listing";
import useMinimalBlogConfig from "../hooks/use-minimal-blog-config";
import replaceSlashes from "../utils/replaceSlashes";
import SEO from "./seo";

type Props = {
  pageContext;
  data;
};

type PostsProps = {
  posts: {
    slug: string;
    title: string;
    date: string;
    excerpt: string;
    description: string;
    timeToRead?: number;
    tags?: {
      name: string;
      slug: string;
    }[];
    banner?: {
      childImageSharp: {
        fluid;
      };
    };
  }[];
  pageContext;
};

const Blog = ({ posts, pageContext }: PostsProps) => {
  const { tagsPath, basePath, blogPath } = useMinimalBlogConfig();

  // console.log('Number of posts in blog component:', Object.keys(posts).length)

  const { currentPage, totalPages } = pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === totalPages;
  const prevPage = currentPage - 1 === 1 ? "" : (currentPage - 1).toString();
  const prevPagePath = `/${basePath}/${blogPath}/${prevPage}`.replace(
    /\/\/+/g,
    `/`
  );
  const nextPage = (currentPage + 1).toString();
  const nextPagePath = `/${basePath}/${blogPath}/${nextPage}`.replace(
    /\/\/+/g,
    `/`
  );

  return (
    <Layout>
      <SEO title="Blog" />
      <Flex
        sx={{
          alignItems: `center`,
          justifyContent: `space-between`,
          flexFlow: `wrap`,
        }}
      >
        <Styled.h2>Blog</Styled.h2>
        <Styled.a
          as={Link}
          sx={{ variant: `links.secondary` }}
          to={replaceSlashes(`/${basePath}/${tagsPath}`)}
        >
          View all tags
        </Styled.a>
      </Flex>
      <Flex
        sx={{
          mt: [2, 3, 4],
          variant: `links.secondary`,
          alignItems: `center`,
          justifyContent: `space-between`,
          flexFlow: `wrap`,
        }}
      >
        {!isFirst && (
          <Link to={prevPagePath} rel="prev">
            ← Newer Posts
          </Link>
        )}

        {!isLast && (
          <Link to={nextPagePath} rel="next">
            Older Posts →
          </Link>
        )}
      </Flex>
      {/* <p>Total posts = {posts.length}</p> */}
      <Listing
        posts={posts}
        sx={{
          mt: [2, 3, 4],
        }}
      />
      <Flex
        sx={{
          mt: [2, 3, 4],
          variant: `links.secondary`,
          alignItems: `center`,
          justifyContent: `space-between`,
          flexFlow: `wrap`,
        }}
      >
        {!isFirst && (
          <Link to={prevPagePath} rel="prev">
            ← Newer Posts
          </Link>
        )}
        <section>
          Page {currentPage} of {totalPages}
        </section>
        {!isLast && (
          <Link to={nextPagePath} rel="next">
            Older Posts →
          </Link>
        )}
      </Flex>
    </Layout>
  );
};

export default ({ data, pageContext }: Props) => {
  const { allPost } = data;

  return <Blog posts={allPost.nodes} pageContext={pageContext} />;
};

// export default ({ pageContext }: Props) => {
//   const {
//     posts,
//     limit,
//     skip,
//     totalPages,
//     currentPage
//   } = pageContext

//   return (
//     <Blog
//       posts={posts}
//       limit={limit}
//       skip={skip}
//       totalPages={totalPages}
//       currentPage={currentPage}
//     />
//     )
// }
